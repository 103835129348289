<template>
  <b-container class="vehicle-details">
    <h3 class="text-primary title text-center">{{ $t("Reservation.VehiclePrice") }}</h3>

    <TheListingCard />

    <div class="mt-5 mb-4">
      <h5 class="upload-title">
        {{ $t("ListingProcess.priceEnterTitle") }}
      </h5>
      <b-alert show dismissible variant="primary" class="alert alert-box info-box">
      <span>
        {{ $t("ListingProcess.priceEnterWarn") }}
      </span>
    </b-alert>
      <p v-if="priceError" class="text-danger text-center">{{ $t("Validations.ListingProcess.maxPrice") }}</p>
      <b-input-group class="setprice-box">
        <b-input-group-prepend class="border left-rounded">
          <country-flag style="max-height: 36px" country="CA" />
          
          <span class="my-auto mr-2">{{ $store.getters.currencyForSeller }}</span>
        </b-input-group-prepend>

        <b-form-input
          type="number"
          min="0"
          class="right-rounded"
          :placeholder="$t('ListingProcess.priceEnterPlaceholder')"
          v-model="vehiclePrice"
          @change="saveInput('vehiclePrice', vehiclePrice)"
        />
      </b-input-group>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-10">
      <div class="d-flex justify-content-between align-items-center">
        <b-button pill variant="half-dark" class="px-3" v-b-modal.cancelModal>
          <v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
        </b-button>
      </div>
        <TheSaveAndLeave />
      </div>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  components: {
    TheListingCard: () => import("@/components/listing/TheListingCard"),
    TheSaveAndLeave: () => import("@/components/listing/TheSaveAndLeaveButton"),
  },
  props: {
    nextButton: { type: Function },
  },
  data() {
    return {
      vehiclePrice: this.$store.getters.vehiclePrice,
      priceError: false
    };
  },
  methods: {
    async sendPrice() {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          Amount: this.vehiclePrice,
          CurrencyCode: this.$store.getters.currency,
        });
      } catch (error) {
        console.log(error.response.data);
      }
    },
    saveInput(item, value) {
      let currentPrice = Math.abs(value);
      if (currentPrice > 100000) {
        this.priceError = true;
      } else {
        this.priceError = false
      }
      this.$store.dispatch(item, currentPrice);
      this.checkNextButton();
    },
    checkNextButton() {
      if (this.$store.getters.vehiclePrice > 0 && this.$store.getters.vehiclePrice <= 100000 ) {
        this.nextButton("page4", false);
      } else {
        this.nextButton("page4", true);
      }
    },
  },
  beforeMount() {
    this.checkNextButton();
  },
  beforeDestroy() {
    this.sendPrice();
  },
};
</script>

<style scoped lang="scss">
.cancelButton {
  margin-left: 210px;
}
</style>

<style lang="scss">
.setprice-box {
  max-width: 458px;
  height: 48px;
  margin: 64px auto;
  input {
    height: 100%;
    box-shadow: none !important;
    border-color: #acacac;
    &::placeholder {
      color: #a4a4a4 !important;
      font-size: 16px;
    }
  }
  .input-group-prepend {
    display: flex;
    align-items: center;
    border-color: #acacac !important;
    span {
      color: #4b4b4b;
      font-size: 14px;
    }
  }
}
</style>
